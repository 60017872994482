// @flow

import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { libs as CONFIG_LIBS } from 'Config';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { googleBtnContainerCls } from './Button.less';

type Props = {
  setToken: Function,
  text: string,
  locale?: string,
};

/**
 * GoogleButton
 *
 */
export const GoogleButton = ({ setToken, text }: Props): Node => {
  const BTN_ID = 'googleLoginBtn';
  const [libLoaded, setLibLoaded] = useState(0);
  const { name: locale } = useSelector(localeSelector, shallowEqual) || {};

  useEffect(() => {
    const isGoogleAuthInit =
      typeof window !== 'undefined' &&
      window.google &&
      window.google.accounts &&
      window.google.accounts.id &&
      window.googleAccountsIdInitialized;

    if (isGoogleAuthInit && libLoaded <= 0) {
      setLibLoaded(1);
      window.google.accounts.id.renderButton(document.getElementById(BTN_ID), {
        theme: 'filled_black',
        text,
        logo_alignment: 'left',
        ...(locale ? { locale } : null),
      });

      window[CONFIG_LIBS.google.callbackName] = setToken;
    } else if (libLoaded <= 0) {
      setTimeout(() => {
        setLibLoaded(libLoaded - 1);
      }, 200);
    }
  }, [text, setToken, libLoaded, locale]);

  /**
   * @returns {Node}
   */
  return <div id={BTN_ID} className={googleBtnContainerCls} />;
};
